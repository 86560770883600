import React from "react";

import close from "../../../assets/images/map-image/modal-icons/close-button.svg";
import { useTranslation } from "react-i18next";

const RequestBriefModal = ({ onCancel, onSuccess, isCancellable }) => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [message, setMessage] = React.useState("");

  return (
    <div className={"modal-holder"}>
      <img
        className={"modal-close-button"}
        src={close}
        alt="modal"
        width={"30x"}
        height={"30px"}
        onClick={onCancel}
      ></img>

      <p className="modal-title">{t("request_a_brief")}</p>
      <p className="modal-body">{t("request_a_brief_body")}</p>
      <div style={{ width: "100%" }}>
        <div className="app-form-group">
          <input
            className="app-form-control"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder={t("contact_us_placeholder_name")}
          />
        </div>
        <div className="app-form-group">
          <input
            className="app-form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("contact_us_placeholder_email")}
          />
        </div>
        <div className="app-form-group">
          <input
            className="app-form-control"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder={t("contact_us_placeholder_phone")}
          />
        </div>
        <div className="app-form-group message">
          <input
            className="app-form-control"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={t("contact_us_placeholder_message")}
          />
        </div>
      </div>
      <div className={"modal-buttons-holder"}>
        {isCancellable && (
          <a className={"modal-cancel-button"} onClick={onCancel} href="/#">
            {t("cancel")}
          </a>
        )}
        <a
          className={"button mt-3"}
          onClick={() => onSuccess(email, fullName, phoneNumber, message)}
          href="/#"
        >
          {t("okay")}
        </a>
      </div>
    </div>
  );
};

export default RequestBriefModal;
