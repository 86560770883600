import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NavigationHeader from "../components/layout/partials/NavigationHeader";
import ScrollToTop from "../utils/ScrollToTop";

import Admin from "./admin";
import Dashboard from "./admin/layouts/dashboard";
import SimpleTool from "./SimpleTool";

const Community = () => {
  const { t } = useTranslation();
  let [, setHovered] = React.useState(false);
  let [selected, setSelected] = useState(0);

  let menus = [
    { index: 0, name: "Basic" },
    { index: 1, name: "Advanced" },
  ];
  return (
    // <section className="body-wrap">
    //   <ScrollToTop />
    //   <div className="container">
    //     <div className="hero-inner">
    //       <div className="hero-copy" style={{ flex: 3 }}>
    //         <NavigationHeader
    //           location={[t("tool")]}
    //           title={t("tool")}
    //           description={t("about_description")}
    //         />
    //       </div>
    //     </div>

    //     <Admin />

    //   </div>

    //   <br />
    //   <br />
    //   <br />
    // </section>
    <div>
      <Admin />
    </div>
  );
};

export default Community;
