import React from "react";
import CheckboxEmpty from "../../assets/icons/input/checkbox-empty.svg";
import CheckboxFilled from "../../assets/icons/input/checkbox-filled.svg";
import Image from "./Image";

const CheckBox = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
        marginTop: 10,
        marginHorizontal: 5,
      }}
    >
      <div
        onClick={props.onClick}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
          marginTop: 10,
          marginHorizontal: 5,
          columnGap: "12px",
          cursor: "pointer",
        }}
      >
        {props.isChecked ? (
          <div>
            <Image src={CheckboxEmpty} size={30} />
          </div>
        ) : (
          <div>
            <Image src={CheckboxFilled} size={30} />
          </div>
        )}
        <p
          className="typography-body"
          style={{ marginBottom: "0", textAlign: "left" }}
        >
          {props.title}
        </p>
      </div>
    </div>
  );
};

export default CheckBox;
