import React, { Component } from 'react';
export default class MultipleImageUploadComponent extends Component {
    fileArray = [];
    constructor(props) {
        super(props)
        this.state = {
            file: [],
            fileArray: []
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
    }
    uploadMultipleFiles(e) {

        let objects = e.target.files
        let promises = []
        let files = [];

        for (let i = 0; i < objects.length; i++) {
            promises.push(this.getBase64(objects[i]));        
        }

        Promise.all(promises).then(results => {
            let parsed = results.map(base64 => base64.split(",")[1]);
            this.setState({fileArray: results})

            this.props.setImages(parsed)
        })

    }

    getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(baseURL);
          };
        });
    }

    render() {
        return (
            <form>
                <div className="form-group multi-preview">
                    {(this.state.fileArray).map(url => {
                        return <div style={{cursor: "pointer"}} 
                        onClick={()=>{
                            let filtered = this.state.fileArray.filter(e => e !== url)
                            console.log(filtered)
                            this.setState({ fileArray:  filtered})
                            this.props.setImages(filtered)
                        }}
                        >
                        <img src={`${url}`} width={70} height={70} style={{margin: 10, borderRadius: "10px", aspectRatio: 1, objectFit: "cover"}} alt="..." />
                        </div>
                    }
                    )}
                </div>
                <div className="">
                    <input type="file" className="file-selector-button" onChange={this.uploadMultipleFiles} multiple />
                </div>
            </form >
        )
    }
}