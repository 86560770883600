export const infographics = [
  {
    id: 0,
    number: "6+",
    text: "infographics_1",
  },
  {
    id: 1,
    number: "25+",
    text: "infographics_2",
  },
  {
    id: 2,
    number: "5+",
    text: "infographics_3",
  },
];
