import { HOST_URL } from "../constants";
import axios from "axios";
import { makeHeaders } from "../utils";

export const authenticateUser = (login, password, rememberMe) => {
  return axios.post(
    `${HOST_URL}/api/authenticate`,
    {
      username: login,
      password,
      rememberMe,
    },
    {}
  );
};

export const registerUser = (login, password, email) => {
  return axios.post(`${HOST_URL}/api/register`, {
    login,
    password,
    email,
  });
};

export const recoverPassword = (email, bearerToken) => {
  let data = {
    email: email,
  };

  return axios.post(`${HOST_URL}/api/user/forgot-password`, data, {
    headers: makeHeaders(bearerToken),
  });
};
