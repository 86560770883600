import React from "react";
import { useTranslation } from "react-i18next";
import { RiCloseFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { authenticateUser } from "../actions/UserAction";
import { SIGN_IN } from "../constants";
import { ButtonSocial } from "components/elements/ButtonSocial";
import CheckBox from "components/elements/Checkbox";

const SignIn = ({ onComplete, onCancel, small }) => {
  const { t } = useTranslation();
  let dispatch = useDispatch();

  let [email, setEmail] = React.useState("");
  let [password, setPassword] = React.useState("");
  let [remember, setRemember] = React.useState(false);

  const signIn = () => {
    authenticateUser(email, password, remember)
      .then(function (response) {
        if (response.error) {
          return dispatch({
            error: response.error,
            errorDescription: response.error_description,
          });
        } else {
          onComplete();

          return dispatch({
            type: SIGN_IN,
            payload: response.data.id_token,
          });
        }
      })
      .catch(function (error) {
        toast("wrong_email_password");
        return dispatch({
          error: error.error,
          errorDescription: error.error_description,
        });
      });
  };

  const notify = (text) => toast(text);

  return (
    <div>
      <div className="sign-in-container">
        <div className={"container"} id="container">
          <RiCloseFill
            className={"modal-close-button"}
            alt="modal"
            onClick={onCancel}
            style={{ color: "white", height: "30px", width: "30px" }}
            stroke={"#ffffff"}
          />
          <div className="form-container sign-in-container">
            <form action="#">
              <h1 className="typography-h2">{t("sign_in")}</h1>

              <span className="typography-h6">{t("use_account")}</span>
              <div className="product-title form-paragraph">
                <input
                  type="input email"
                  placeholder={t("signup_email_placeholder")}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="product-title form-paragraph">
                <input
                  type={"password"}
                  placeholder={t("signup_password_placeholder")}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div>
                <CheckBox
                  title={t("signup_checkbox")}
                  isChecked={!remember}
                  onClick={() => setRemember(!remember)}
                />
              </div>

              <p
                style={{
                  width: "100%",
                  marginBottom: 0,
                }}
                className="button-white"
                onClick={signIn}
              >
                {t("sign_in")}
              </p>

              <Link
                to="/signup"
                className="button-secondary"
                style={{
                  width: "100%",
                }}
                id="signup"
              >
                {t("sign_up")}
              </Link>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  columnGap: "12px",
                  marginTop: "12px",
                  marginBottom: "12x",
                }}
              >
                <div className="divider" style={{ flex: 2 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="typography-body"
                    style={{ flex: 1, marginBottom: 0 }}
                  >
                    {t("or_use")}
                  </p>
                </div>
                <div className="divider" style={{ flex: 2 }} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  columnGap: "20px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonSocial variant={"google"} />
                <ButtonSocial variant={"facebook"} />
                <ButtonSocial variant={"apple"} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
