export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
  },
};

export const customSignInModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "5px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "40px",
  },
};

export const signInModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "5px",
    backgroundColor: "transparent",
    transform: "translate(-50%, -50%)",

    border: "none",
    width: "100%",
  },
};
