/**
=========================================================
* The Short
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react


Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// The Short React components
import MDBox from "components/MDBox";

// The Short React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/admin/layouts/billing/components/PaymentMethod";
import Invoices from "layouts/admin/layouts/billing/components/Invoices";
import ShortsList from "layouts/admin/layouts/billing/components/ShortsList";
import Transactions from "layouts/admin/layouts/billing/components/Transactions";
import Modal from "react-modal";
import { customSignInModalStyles } from "utils";
import { Card, Icon } from "@mui/material";
import { useState } from "react";
import CreateShort from "layouts/CreateShort";
import MDTypography from "components/MDTypography";

function Shorts() {
  let [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    console.log("Close modal");
    setIsOpen(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3.25rem"
        height="3.25rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="2rem"
        zIndex={99}
        color="primary"
        sx={{ cursor: "pointer" }}
        onClick={() => setIsOpen(true)}
      >
        <Icon fontSize="small" color="inherit">
          add
        </Icon>
      </MDBox>

      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Card item xs={12} md={12}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Shorts
                </MDTypography>
              </MDBox>
              <ShortsList />
            </Card>
          </Grid>
        </MDBox>
      </MDBox>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: { ...customSignInModalStyles.content, height: "90%" },
        }}
        overlayClassName="Overlay"
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <CreateShort onComplete={closeModal} onCancel={closeModal} />
      </Modal>
    </DashboardLayout>
  );
}

export default Shorts;
