/**
=========================================================
* The Short
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react


Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// The Short React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// The Short React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import authorsTableData from "layouts/admin/layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/admin/layouts/tables/data/projectsTableData";
import UsersList from "../tables/UsersList";
import CategoryList from "./CategoryList";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

function Categories() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Categories
                </MDTypography>
              </MDBox>

              <MDBox pt={3} px={3}>
                <CategoryList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Categories;
