import React, { useState } from "react";
import { Link } from "react-router-dom";

const Logo = ({ elements }) => {
  let [selected, setSelected] = useState(undefined);
  // let [elements, setElements] = useState([]);

  // useEffect(() => {
  //   getProjectHeaders(navigator.language).then((res) => {
  //     console.log(res.data);
  //     setElements(res.data.slice(0, 5));
  //   });
  // }, []);

  return (
    <div className={"container logos-holder"}>
      {elements.map((element, index) => {
        return (
          <Link
            to={`/project/${element.id}`}
            className={"logo-holder"}
            key={index}
          >
            <div
              alt="project-logo"
              class={selected === element ? "filter-blue" : ""}
              onMouseEnter={() => {
                setSelected(element);
              }}
              onMouseLeave={() => {
                setSelected(undefined);
              }}
            >
              {element.image}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Logo;
