import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import ActionModal from "../components/layout/partials/ActionModal";
import RequestBriefModal from "../components/layout/partials/RequestBriefModal";
import { sendMessage } from "../actions";
import { Link } from "react-router-dom";
import ScrollToTop from "../utils/ScrollToTop";
import LogoFullVertical from "../components/layout/partials/LogoFullVertical";
import Image from "../components/elements/Image";
import google from "../assets/images/google.png";
import apple from "../assets/images/appstore.svg";
import encryption from "../assets/emoji/heart-eyes.svg";
import design from "../assets/emoji/robot.svg";
import access from "../assets/emoji/pen.svg";
import Subscribe from "../components/Subscribe";
import Video from "../assets/videos/sample.mp4";
import VideoAZ from "../assets/videos/sample_az.mp4";
import VideoRU from "../assets/videos/sample_ru.mp4";
import { infographics } from "assets/static/infographics";
import { useSelector } from "react-redux";

const Home = () => {
  const { t } = useTranslation();
  const [modalContent, setModalContent] = React.useState({
    type: "block",
    title: t("message_sent_error"),
    body: t("message_sent_error_description"),
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [actionModalIsOpen, setActionModalIsOpen] = React.useState(false);
  let language = useSelector((state) => state.navigationReducer.language);

  function openModal() {
    setIsOpen(true);
  }

  function sendActionMessage(email, fullName, phoneNumber, message) {
    sendMessage(fullName, email, phoneNumber, message)
      .then((res) => {
        setModalContent({
          type: "congrats",
          title: t("message_sent_successfully"),
          body: t("message_sent_successfully_description"),
        });
        closeModal();
        setActionModalIsOpen(true);
      })
      .catch((e) => {
        setModalContent({
          type: "block",
          title: t("message_sent_error"),
          body: t("message_sent_error_description"),
        });
        closeModal();
        setActionModalIsOpen(true);
      });
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    document.getElementById("mutiny-video").play();
  });

  function renderVideo() {
    switch (language) {
      case "az":
        return VideoAZ;
      case "ru":
        return VideoRU;
      case "en":
        return Video;
    }
  }

  function closeActionModal() {
    setActionModalIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const features = [
    {
      index: 1,
      name: "features_1",
      image: encryption,
    },
    {
      index: 2,
      name: "features_2",
      image: design,
    },
    {
      index: 3,
      name: "features_3",
      image: access,
    },
  ];

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div
        style={{
          zIndex: "1",
        }}
      ></div>
      <div
        className="background-gradient"
        style={{
          position: "absolute",
          zIndex: -1,
        }}
      ></div>
      <div className="container">
        <div
          className="hero-inner"
          style={{ height: window.innerHeight - 150 }}
        >
          <div
            className="hero-copy info-box"
            style={{
              marginTop: 50,
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div style={{ columnGap: 100 }} className={"hero-holder"}>
              <div className="hero-left">
                <p
                  className="home-title"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span className="home-title-light">{t("home_title")}</span>
                  <span className="home-title-dark">{t("home_subtitle")}</span>
                </p>

                {/* <div className="home-illustration">
              <Image src={illustration} width={400} height={400} />
            </div> */}

                <div className="mt-5 w-100">
                  <input
                    className="landing-email"
                    placeholder={t("enter_your_email")}
                  />
                </div>
                <div className="hero-cta">
                  <p className="button-white" href="/#" onClick={openModal}>
                    {t("try_it_now")}
                  </p>
                  <Link to="/about" className="button-secondary">
                    {t("learn_more")}
                  </Link>
                </div>
              </div>
              <div className="hero-right">
                {/* <LogoFullVertical width={150} height={150} /> */}

                <div
                  class="video-component video-component--mutiny"
                  style={{
                    backgroundColor: "transparent",
                    boxShadow: "0px 0px 0px rgba(16, 30, 54, 0.09)",
                  }}
                >
                  <video
                    id="mutiny-video"
                    className="video-holder"
                    muted
                    width="1200"
                    height="802"
                    poster={LogoFullVertical}
                    autoplay
                    src={renderVideo()}
                    loop
                    playsinline=""
                  ></video>
                </div>
              </div>
            </div>
            <div className="infographics-holder-home w-100 m-auto mb-0">
              {infographics.map((element, index) => (
                <div className="infographics-item-holder" key={index}>
                  <p className="infographics-number">{element.number}</p>
                  <p className="infographics-text">{t(element.text)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />

        <p className="path-description typography-heading-large text-center bold mb-2">
          {t("home_header")}
        </p>

        <div className="hero-secondary">
          <p className="hero-secondary-body">{t("welcome_description")}</p>
        </div>

        <div
          className={"container services-holder"}
          style={{ marginTop: "50px", zIndex: 10 }}
        >
          {features.map((element, index) => {
            return (
              <p className={"service-card"} key={index}>
                <div className="service-image-background">
                  <Image
                    className={"service-image"}
                    src={element.image}
                    width={90}
                    height={90}
                  />
                </div>
                <p className="service-title">{t(element.name)}</p>
                <p className="service-description">
                  {t(element.name + "_description")}
                </p>
              </p>
            );
          })}
        </div>
        <br />
        <br />

        <div className={"store-buttons"}>
          <Link to="/play">
            <Image src={google} width={200} height={600} />
          </Link>
          <Link to="/store">
            <Image src={apple} width={165} height={600} />
          </Link>
        </div>

        <div>
          <div className="hero-secondary">
            <Subscribe />
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        // className="react-modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <RequestBriefModal
          onCancel={closeModal}
          onSuccess={sendActionMessage}
          isCancellable={false}
        />
      </Modal>
      <Modal
        isOpen={actionModalIsOpen}
        onRequestClose={closeActionModal}
        style={customStyles}
        // className="react-modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <ActionModal
          title={modalContent.title}
          body={modalContent.body}
          type={modalContent.type}
          onCancel={closeActionModal}
          onSuccess={closeActionModal}
          isCancellable={false}
        />
      </Modal>
    </section>
  );
};

export default Home;
