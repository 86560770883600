export const footerNav = [
  {
    title: "Mobile app",
    index: 1,
    name: "mobile-app",
    subsections: [
      {
        title: "Features",
        index: 1,
        path: "/features",
        name: "features",
        component: <></>,
      },
      {
        title: "Apple Store",
        index: 1,
        path: "/apple-store",
        name: "Apple Store",
        component: <></>,
      },
      {
        title: "Play Market",
        index: 1,
        path: "/play-market",
        name: "Play Market",
        component: <></>,
      },
    ],
  },
  {
    title: "Community",
    index: 2,
    name: "community",
    subsections: [
      {
        title: "Facebook",
        index: 1,
        path: "/facebook",
        name: "Facebook",
        component: <></>,
      },
      {
        title: "Telegram",
        index: 1,
        path: "/telegram",
        name: "Telegram",
        component: <></>,
      },
      {
        title: "Live events",
        index: 1,
        path: "/instagram",
        name: "Instagram",
        component: <></>,
      },
    ],
  },
  {
    title: "Company",
    index: 3,
    name: "company",
    subsections: [
      {
        title: "About us",
        index: 1,
        path: "/about",
        name: "about-us",
        component: <></>,
      },
      {
        title: "Contact us",
        index: 1,
        path: "/about",
        name: "contact-us",
        component: <></>,
      },
    ],
  },
];
