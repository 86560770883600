import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { customStyles } from "../utils/ModalStyles";
// import ActionModal from "../../components/layout/partials/ActionModal";
// import { signUpByEmailPassword } from "../../actions/AuthAction";
import { validateEmail } from "../utils";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";
import { registerUser } from "../actions/UserAction";
import ActionModal from "../components/layout/partials/ActionModal";
import ScrollToTop from "utils/ScrollToTop";
import NavigationHeader from "components/layout/partials/NavigationHeader";
import { ButtonSocial } from "components/elements/ButtonSocial";
import CheckBox from "components/elements/Checkbox";
import Image from "components/elements/Image";

const SignUp = () => {
  const { t } = useTranslation();

  let [modalIsOpen, setIsOpen] = useState(false);
  const [submitModalIsOpen, setSubmitIsOpen] = React.useState(false);
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [login, setLogin] = useState("");
  let [dateOfBirth, setDateOfBirth] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [titleError, setTitleError] = useState("");
  let [dateOfBirthError, setDateOfBirthError] = useState("");

  let [firstNameError, setFirstNameError] = useState("");
  let [lastNameError, setLastNameError] = useState("");
  let [emailError, setEmailError] = useState("");
  let [loginError, setLoginError] = useState("");
  let [passwordError, setPasswordError] = useState("");
  let [confirmPasswordError, setConfirmPasswordError] = useState("");
  let [showPassword, setShowPassword] = useState(false);
  let [showConfirmPassword, setShowConfirmPassword] = useState(false);
  let [checkbox, setCheckbox] = useState(false);
  let [image, setImage] = useState(undefined);
  const [modalContent, setModalContent] = React.useState({
    type: "block",
    title: t("message_sent_error"),
    body: t("message_sent_error_description"),
  });

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  let pickImage = () => {};

  function openSubmitModal() {
    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      login: login,
      password: password,
    };

    // registerUser(login, password, email)
    //   .then((res) => {
    //     setModalContent({
    //       type: "congrats",
    //       title: t("signup_sent_successfully"),
    //       body: t("signup_sent_successfully_description"),
    //     });
    //     setSubmitIsOpen(true);
    //   })
    //   .catch((e) => {
    //     setModalContent({
    //       type: "block",
    //       title: t("signup_sent_error"),
    //       body: t("signup_sent_error_description"),
    //     });
    //     setSubmitIsOpen(true);
    //   });

    setModalContent({
      type: "congrats",
      title: t("signup_sent_successfully"),
      body: t("signup_sent_successfully_description"),
    });
    setSubmitIsOpen(true);
  }

  function closeSubmitModal() {
    setSubmitIsOpen(false);
  }

  const checkFields = () => {
    if (email.length === 0 || validateEmail(email) === false) {
      setTitleError(true);
    }
    if (
      password.length === 0 ||
      confirmPassword.length < 4 ||
      password.length > 16
    ) {
      setPasswordError(true);
    }
    if (confirmPassword.length === 0 && confirmPassword !== password) {
      setConfirmPasswordError(true);
    }
    if (firstName.length === 0) {
      setFirstNameError(true);
    }
    if (lastName.length === 0) {
      setLastNameError(true);
    }
    if (login.length === 0) {
      setLoginError(true);
    } else if (
      !loginError &&
      !emailError &&
      !passwordError &&
      !confirmPasswordError &&
      !firstNameError &&
      !lastNameError &&
      !loginError
    ) {
      openSubmitModal();
    }
  };
  return (
    <section
      className="body-wrap"
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("profile")]}
              title={t("sign_up")}
              description={t("home_subtitle")}
            />
          </div>
        </div>

        <div>
          <div className="hero-secondary w-100">
            <p className="hero-secondary-body">{t("signup_description")}</p>
          </div>
        </div>
      </div>
      <div className="signup-form-holder">
        <div className="form-holder" style={{ marginTop: "20px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              marginBottom: "18px",
            }}
          >
            <img
              src={
                image
                  ? image
                  : "https://api.dicebear.com/5.x/shapes/png?seed=sign-up"
              }
              style={{
                width: 100,
                height: 100,
                borderRadius: 50,
                cursor: "pointer",
              }}
              onClick={pickImage}
            />
          </div>

          <div className="product-title form-paragraph name-surname-holder">
            <input
              type="text"
              className={"input " + (firstNameError ? "error" : "")}
              placeholder={t("signup_firstname_placeholder")}
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <input
              type="text"
              className={"input " + (lastNameError ? "error" : "")}
              placeholder={t("signup_lastname_placeholder")}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>

          <div className="product-title form-paragraph">
            <input
              type="text"
              className={"input " + (emailError ? "error" : "")}
              placeholder={t("signup_email_placeholder")}
              value={email}
              onChange={(e) => {
                titleError && setTitleError(false);
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="product-title form-paragraph">
            <input
              type="text"
              className={"input " + (dateOfBirthError ? "error" : "")}
              placeholder={t("signup_date_of_birth_placeholder")}
              value={login}
              onChange={(e) => {
                dateOfBirthError && setDateOfBirthError(false);
                setDateOfBirth(e.target.value);
              }}
            />
          </div>

          <div className="product-title form-paragraph">
            <input
              type="text"
              className={"input " + (loginError ? "error" : "")}
              placeholder={t("signup_login_placeholder")}
              value={login}
              onChange={(e) => {
                titleError && setTitleError(false);
                setLogin(e.target.value);
              }}
            />
          </div>

          <div className="product-title form-paragraph password-holder">
            <input
              type={!showPassword ? "password" : "text"}
              className={"input " + (passwordError ? "error" : "")}
              placeholder={t("signup_password_placeholder")}
              value={password}
              onChange={(e) => {
                titleError && setTitleError(false);
                setPassword(e.target.value);
              }}
            />
            <div
              className="input-eye-holder"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <RiEyeFill size={20} color={"#8195fd"} />
              ) : (
                <RiEyeCloseFill size={20} color={"#8195fd"} />
              )}
            </div>
          </div>

          <div className="product-title form-paragraph  password-holder">
            <input
              type={!showConfirmPassword ? "password" : "text"}
              className={"input " + (confirmPasswordError ? "error" : "")}
              placeholder={t("signup_confirm_password_placeholder")}
              value={confirmPassword}
              onChange={(e) => {
                titleError && setTitleError(false);
                setConfirmPassword(e.target.value);
              }}
            />
            <div
              className="input-eye-holder"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <RiEyeFill size={20} color={"#8195fd"} />
              ) : (
                <RiEyeCloseFill size={20} color={"#8195fd"} />
              )}
            </div>
          </div>

          <div>
            <CheckBox
              title={t("signup_checkbox")}
              isChecked={!checkbox}
              onClick={() => setCheckbox(!checkbox)}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            columnGap: "20px",
            marginTop: "20px",
            marginBottom: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonSocial variant={"google"} />
          <ButtonSocial variant={"facebook"} />
          <ButtonSocial variant={"apple"} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            columnGap: "20px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div className="divider" style={{ flex: 2 }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="typography-body" style={{ flex: 1, marginBottom: 0 }}>
              {t("or_use")}
            </p>
          </div>
          <div className="divider" style={{ flex: 2 }} />
        </div>

        <div className="add-product-button">
          <p
            style={{ width: "100%" }}
            className="button-white"
            onClick={() => {
              checkFields();
            }}
          >
            {t("signup_placeholder")}
          </p>
        </div>
        <div className="add-product-button">
          <p
            style={{ width: "100%" }}
            className="button-secondary"
            onClick={() => {}}
          >
            {t("signin_placeholder")}
          </p>
        </div>
      </div>

      <Modal
        isOpen={submitModalIsOpen}
        onRequestClose={closeSubmitModal}
        style={customStyles}
        ariaHideApp={false}
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <ActionModal
          title={modalContent.title}
          body={modalContent.body}
          type={modalContent.type}
          onCancel={closeSubmitModal}
          onSuccess={closeSubmitModal}
          isCancellable={false}
        />
      </Modal>
    </section>
  );
};

export default SignUp;
