import {
  MENU_CHANGE,
  ADMIN_MENU_CHANGE,
  LANGUAGE_CHANGE,
  back,
  HOST_URL,
} from "../constants";
import axios from "axios";
import { makeHeaders } from "../utils";
import store from "../store";
import navigationReducer from "../reducers/navigationReducer";
import { useTranslation } from "react-i18next";

export function setLanguage(language, dispatch, i18n, t) {
  i18n.changeLanguage(language);

  switch (language) {
    case "en":
      document.title = "The Short - Stay Informed, Stay Ahead.";
      break;
    case "ru":
      document.title = "The Short - Будьте в курсе, будьте впереди.";
      break;
    default:
      document.title = "Qısaca - Xəbərdar olun, Öndə olun.";
      break;
  }

  return dispatch({
    type: LANGUAGE_CHANGE,
    payload: language,
  });
}

export function sendMessage(fullName, email, phoneNumber, message) {
  let link = `${back}/api/contacts`;

  var myHeaders = {
    "Content-Type": "application/json",
  };

  let data = {
    email: email,
    fullName: fullName,
    phoneNumber: phoneNumber,
    message: message,
  };

  return axios.post(link, data, { headers: myHeaders });
}

export function addEmailToNewsletter(email) {
  let link = `${back}/api/newstellers`;

  var myHeaders = {
    "Content-Type": "application/json",
  };

  let data = {
    email: email,
  };

  return axios.post(link, data, { headers: myHeaders });
}

export function setMenu(menu, dispatch) {
  return dispatch({
    type: MENU_CHANGE,
    payload: menu,
  });
}

export function setAdminMenu(menu, dispatch) {
  console.log(menu);
  return dispatch({
    type: ADMIN_MENU_CHANGE,
    payload: menu,
  });
}

export function generateImage(data, bearerToken) {
  let link = `${HOST_URL}/api/model/generate-image`;
  let header = makeHeaders(bearerToken);

  console.log(header);

  return axios.post(link, data, { headers: header });
}

export function generateAudio(data) {
  let link = ``;
  let header = {};

  return axios.post(link, data, { headers: header });
}
