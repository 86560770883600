import React from "react";
import FacebookIcon from "../../assets/social/facebook.svg";
import AppleIcon from "../../assets/social/apple.svg";
import GoogleIcon from "../../assets/social/google.svg";
import Image from "./Image";

const renderIcon = (variant, disabled, width, height) => {
  switch (variant) {
    case "apple":
      return (
        <div className={!disabled ? "button-social" : "button-social-disabled"}>
          <Image src={AppleIcon} />
        </div>
      );
    case "google":
      return (
        <div className={!disabled ? "button-social" : "button-social-disabled"}>
          <Image src={GoogleIcon} />
        </div>
      );
    default:
      return (
        <div className={!disabled ? "button-social" : "button-social-disabled"}>
          <Image src={FacebookIcon} />
        </div>
      );
  }
};

export const ButtonSocial = ({
  callback,
  variant = "facebook",
  disabled = false,
}) => {
  return (
    <div onPress={callback} disabled={disabled}>
      {renderIcon(variant, disabled)}
    </div>
  );
};
