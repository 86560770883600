import React from "react";
import { useTranslation } from "react-i18next";
import NavigationHeader from "../components/layout/partials/NavigationHeader";
import ScrollToTop from "../utils/ScrollToTop";
import Image from "../components/elements/Image";
import basic from "../assets/emoji/heart-eyes.svg";
import business from "../assets/emoji/cool.svg";
import { Link } from "react-router-dom";
import google from "../assets/images/google.png";
import apple from "../assets/images/appstore.svg";

const Pricing = () => {
  const { t } = useTranslation();

  const prices = [
    {
      index: 1,
      title: "Free Plan",
      name: "pricing_1",
      image: basic,
      price: "pricing_1_price",
    },
    {
      index: 2,
      title: "Premium Plan",
      name: "pricing_2",
      price: "pricing_2_price",
      image: business,
    },
    // {
    //   index: 3,
    //   title: "Cloud Version",
    //   name: "pricing_3",
    //   price: "pricing_3_price",
    //   image: cloud,
    // },
  ];

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("pricing")]}
              title={t("pricing")}
              description={t("pricing_paragraph_1_body")}
            />
          </div>
        </div>
      </div>

      <div
        className={"container services-holder"}
        style={{
          zIndex: 10,
          width: "100%",
        }}
      >
        {prices.map((element, index) => {
          return (
            <p className={"service-card"} key={index}>
              <div className="service-image-background">
                <Image
                  className={"service-image"}
                  src={element.image}
                  width={90}
                  height={90}
                />
              </div>
              <p className="service-title">{t(element.name)}</p>

              <p className="service-description text-center">
                {t(element.name + "_description")}
              </p>

              <p className="price-title">
                {t(element.price)}
                <span>{t(element.price + "_per")}</span>
              </p>
              <div className="">
                <p className="button-white" href="/#">
                  {t("select_plan")}
                </p>
              </div>
            </p>
          );
        })}
      </div>

      <div className={"store-buttons"}>
        <Link to="/play">
          <Image src={google} width={200} height={600} />
        </Link>
        <Link to="/store">
          <Image src={apple} width={165} height={600} />
        </Link>
      </div>
    </section>
  );
};

export default Pricing;
