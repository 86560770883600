import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";
import logo from "./../../../assets/icons/Light w Color.svg";

const LogoFullVertical = ({ className, height, width, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes} style={{ zIndex: 100 }}>
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={logo}
            width={width ? width : 180}
            height={height ? height : 180}
          />
        </Link>
      </h1>
    </div>
  );
};

export default LogoFullVertical;
