/**
=========================================================
* The Short
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react


Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Grid, Icon } from "@mui/material";
import Card from "@mui/material/Card";

// The Short React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import * as React from "react";
import { DataGrid, GridFilterModel } from "@mui/x-data-grid";
import { createFakeServer } from "@mui/x-data-grid-generator";

// Shorting page components
import Short from "layouts/admin/layouts/billing/components/Short";
import { countries } from "utils/countries";
import { getShortsAll } from "actions/short-actions";
import { useSelector } from "react-redux";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import { getUsersAll } from "actions/user-actions";

const { columns, useQuery } = createFakeServer();

function UsersList() {
  const [queryOptions, setQueryOptions] = React.useState({});
  let userProfile = useSelector((state) => state.navigationReducer.bearerToken);
  let [error, setError] = React.useState(false);
  let [errorMessage, setErrorMessage] = React.useState("");
  let [rowsPerPage, setRowsPerPage] = React.useState(5);
  let [page, setPage] = React.useState(0);
  let [rowCount, setRowCount] = React.useState(100);

  const onFilterChange = React.useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    // setQueryOptions({ filterModel: { ...filterModel } });
    let query = filterModel.items.map(
      (filter) =>
        `${filter.columnField}.${filter.operatorValue}=${filter.value}`
    );

    console.log(query);
  }, []);

  const [isLoading, setIsLoading] = React.useState(false);

  let [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    getUsersAll("", userProfile, 0, 5)
      .then((response) => {
        console.log(response.data);
        setUsers(response.data);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        openError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userProfile]);

  let pageChange = (page, rowsPerPage) => {
    setIsLoading(true);
    getUsersAll("", userProfile, page, rowsPerPage)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        openError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      type: "string",
    },
    {
      field: "username",
      headerName: "Username",
      width: 150,
      type: "string",
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      type: "string",
    },
    { field: "name", headerName: "Name", width: 250, type: "string" },
    {
      field: "country",
      headerName: "Country",
      width: 150,
      type: "singleSelect",
      valueOptions: countries.map((element) => element.label),
    },
    { field: "city", headerName: "City", width: 150, type: "singleSelect" },
    {
      field: "surname",
      headerName: "Surname",
      width: 150,
      type: "string",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      type: "dateTime",
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 150,
      type: "dateTime",
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      type: "string",
    },
    {
      field: "birthday",
      headerName: "Birthday",
      width: 100,
      type: "date",
    },
    {
      field: "isDeleted",
      headerName: "Deleted",
      width: 100,
      type: "boolean",
    },
    {
      field: "uuid",
      headerName: "UUID",
      width: 100,
      type: "string",
    },
  ];

  const openError = () => setError(true);
  const closeError = () => setError(false);

  const renderError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={"Something went wrong!"}
      content={errorMessage}
      dateTime="Just now"
      open={error}
      onClose={closeError}
      close={closeError}
    />
  );

  const [rowCountState, setRowCountState] = React.useState(rowCount);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  return (
    <>
      <Grid container id="delete-account" style={{ width: "100%" }}>
        <Grid
          style={{ height: 500, width: 1000 }}
          px={5}
          mb={3}
          xs={12}
          md={12}
          lg={12}
        >
          <DataGrid
            initialState={{
              pagination: {
                pageSize: 5,
              },
            }}
            rows={users}
            columns={columns}
            page={page}
            pageSize={rowsPerPage}
            paginationMode="server"
            filterMode="server"
            onFilterModelChange={onFilterChange}
            rowsPerPageOptions={[5, 10, 20]}
            loading={isLoading}
            rowCount={rowCountState}
            onPageSizeChange={(newPageSize) => {
              setRowsPerPage(newPageSize);
              pageChange(page, newPageSize);
            }}
            onPageChange={(newPage) => {
              setPage(newPage);
              pageChange(newPage, rowsPerPage);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          {renderError}
        </Grid>
      </Grid>
    </>
  );
}

export default UsersList;
