import axios from "axios";
import { makeHeaders } from "utils";
import { SHORT_HOST_URL } from "../constants";

export const searchForShorts = (prompt, bearerToken, page) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${SHORT_HOST_URL}/api/_search/shorts?query=${prompt}&page=${page}&size=20&sort=createdAt`,
    headers
  );
};

export const getShortsFeed = (bearerTokenpage) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${SHORT_HOST_URL}/api/shorts/search-feed?page=${page}&size=20&sort=createdAt`,
    headers
  );
};

export const likeShort = (id, bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${SHORT_HOST_URL}/api/shorts/${id}/likes`, {}, headers);
};

export const isShortLiked = (id, bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(`${SHORT_HOST_URL}/api/shorts/${id}/likes/current`, headers);
};

export const getShortLikes = (id, bearerToken, page) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${SHORT_HOST_URL}/api/shorts/${id}/likes?page=${page}&size=20&sort=createdAt`,
    headers
  );
};

export const saveShort = (id, bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${SHORT_HOST_URL}/api/shorts/${id}/saves`, {}, headers);
};

export const isShortSaved = (id, bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(`${SHORT_HOST_URL}/api/shorts/${id}/saves/current`, headers);
};

export const getLinkOfShort = (id, bearerToken) => {
  //Ready
  return `${SHORT_HOST_URL}/api/shorts/${id}`;
};

export const getMetadataOfShort = (id, bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(`${SHORT_HOST_URL}/api/shorts/${id}/meta-data`, headers);
};

export const leaveCommentOnShort = (id, props, bearerToken) => {
  //Ready
  let { comment } = props;
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(
    `${SHORT_HOST_URL}/api/shorts/${id}/comments`,
    props,
    headers
  );
};

export const editCommentOnShort = (id, props, bearerToken) => {
  //Ready
  let { comment } = props;
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${SHORT_HOST_URL}/api/comments/${id}`, props, headers);
};

export const likeCommentOnShort = (id, bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${SHORT_HOST_URL}/api/comments/${id}/likes`, {}, headers);
};

export const isCommentOnShortLiked = (id, bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${SHORT_HOST_URL}/api/comments/${id}/likes/current`,
    headers
  );
};

export const reportCommentOnShort = (id, report, bearerToken) => {
  //No endpoint
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${SHORT_HOST_URL}/api/authenticate`, headers);
};

export const deleteCommentOnShort = (id, bearerToken) => {
  //No endpoint
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.delete(`${SHORT_HOST_URL}/api/authenticate`, headers);
};

export const reportShort = (id, props, bearerToken) => {
  //Ready
  let { reason, description } = props;
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(
    `${SHORT_HOST_URL}/api/shorts/${id}/reports`,
    { props },
    headers
  );
};

export const blockShort = (id, bearerToken) => {
  //No endpoint
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${SHORT_HOST_URL}/api/authenticate`, headers);
};

export const getShortComments = (id, bearerToken, page) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${SHORT_HOST_URL}/api/shorts/${id}/comments?page=${page}&size=20`,
    headers
  );
};

export const getSavedShorts = (bearerToken, page) => {
  //No endpoint
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(`${SHORT_HOST_URL}/api/authenticate`, headers);
};

export const getLikedShorts = (bearerToken, page) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${SHORT_HOST_URL}/api/user/likes?page=${page}&size=20`,
    headers
  );
};

export const getBlockedShorts = (bearerToken, page) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${SHORT_HOST_URL}/api/user/blocks?page=${page}&size=20`,
    headers
  );
};

export const getShortsAll = (prompt, bearerToken, page, pageSize) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${SHORT_HOST_URL}/api/shorts/all?query=${prompt}&page=${page}&size=${pageSize}&sort=createdAt`,
    headers
  );
};

export const getCategoriesAll = (bearerToken, page, pageSize) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${SHORT_HOST_URL}/api/categories?page=${page}&size=${pageSize}`,
    headers
  );
};

export const getCategories = (prompt, bearerToken, page, pageSize) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${SHORT_HOST_URL}/api/_search/categories?query=${prompt}&page=${page}&size=${pageSize}`,
    headers
  );
};

export const addShort = (data, bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${SHORT_HOST_URL}/api/shorts`, data, headers);
};
