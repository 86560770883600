import React from "react";
import { useTranslation } from "react-i18next";
import { GrClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import MDInput from "components/MDInput";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { Box } from "@mui/system";
import { countries } from "utils/countries";
import { addShort } from "actions/short-actions";
import MDSnackbar from "components/MDSnackbar";
import { Theme, useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";
import MultipleImageUploadComponent from "components/elements/MultipleImageUpload";
import axios from "axios";

const CreateShort = ({ onComplete, onCancel, small }) => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  let userProfile = useSelector((state) => state.navigationReducer.bearerToken);
  let [error, setError] = React.useState(false);
  let [errorMessage, setErrorMessage] = React.useState("");

  let [success, setSuccess] = React.useState(false);
  let [successMessage, setSuccessMessage] = React.useState(true);

  let [articleName, setArticleName] = React.useState("Test Article Name");
  let [language, setLanguage] = React.useState("EN");
  let [name, setName] = React.useState("Test Short");
  let [country, setCountry] = React.useState("US");
  let [city, setCity] = React.useState("Default");
  let [fullArticle, setFullArticle] = React.useState("Lorem ipsum dolor");
  let [source, setSource] = React.useState("http://www.theshort.com/");
  let [creationDate, setCreationDate] = React.useState("2018-11-23T10:30:00");
  let [isSensitiveContent, setIsSensitiveContent] = React.useState(false);
  let [attachments, setAttachments] = React.useState([])
  const theme = useTheme();
  const [categories, setCategories] = React.useState([]);

  const options = [
    { label: "The Godfather", id: 1 },
    { label: "Pulp Fiction", id: 2 },
  ];

  const openError = () => setError(true);
  const closeError = () => setError(false);

  const openSuccess = () => setSuccess(true);
  const closeSuccess = () => setSuccess(false);

  const postShort = () => {
    let data = {
      articleName: articleName,
      language: language,
      name: name,
      country: country,
      city: city,
      fullArticle: fullArticle,
      source: source,
      isSensitiveContent: isSensitiveContent,
      isVerified: true,
      attachments: attachments
    };
    console.log(data);

    if (userProfile) {
      addShort(data, userProfile)
        .then((response) => {
          console.log(response.data);
          toast("Short has been created");
        })
        .catch((error) => {
          toast(error.message);
          return dispatch({
            error: error.message,
            errorDescription: error.message,
          });
        });
    } else {
      setErrorMessage("Please sign in!");
      openError(true);
    }
  };



  const renderError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={"Something went wrong!"}
      content={errorMessage}
      dateTime="Just now"
      open={error}
      onClose={closeError}
      close={closeError}
    />
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const renderSuccess = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={"Short Posted!"}
      content={successMessage}
      dateTime="Just now"
      open={success}
      onClose={closeSuccess}
      close={closeSuccess}
    />
  );

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  function getStyles(name, categories, theme) {
    return {
      fontWeight:
        categories.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div className="add-short-container">
      <div className={"container"} id="container">
        <GrClose
          className={"modal-close-button"}
          src={GrClose}
          alt="modal"
          width={"30x"}
          height={"30px"}
          onClick={onCancel}
        ></GrClose>

        <MultipleImageUploadComponent setImages={(e)=> {setAttachments(e)}}/>
        <MDInput
          label="Article Name"
          size="small"
          value={articleName}
          onChange={(e) => setArticleName(e.target.value)}
        />
        <MDInput
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <MDInput
          type="url"
          label="URL"
          size="small"
          value={source}
          onChange={(e) => setSource(e.target.value)}
        />

        <FormControl>
          <InputLabel id="demo-multiple-chip-label">Categories </InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            sx={{ py: 2 }}
            value={categories}
            onChange={handleChange}
            input={
              <OutlinedInput id="select-multiple-chip" label="Categories" />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, categories, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            {" "}
            <Autocomplete
              id="country-select-demo"
              size="small"
              options={countries}
              autoHighlight
              onChange={(e, value) => {
                setCountry(value.code);
              }}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  size="small"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a country"
                  size="small"
                  margin="dense"
                  // inputProps={{
                  //   ...params.inputProps,
                  //   autoComplete: "new-password", // disable autocomplete and autofill
                  // }}
                />
              )}
            />{" "}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {" "}
            <Autocomplete
              id="country-select-demo"
              size="small"
              options={countries}
              autoHighlight
              onChange={(e, value) => {
                setLanguage(value.code);
              }}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  size="small"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  unstyled
                  sx={{ height: "40px" }}
                  label="Choose a language"
                  size="small"
                  margin="dense"
                  // inputProps={{
                  //   ...params.inputProps,
                  //   autoComplete: "new-password", // disable autocomplete and autofill
                  // }}
                />
              )}
            />{" "}
          </Grid>
        </Grid>

        <MDInput
          type="datetime"
          label="Date time"
          value="2018-11-23T10:30:00"
          size="small"
          value={creationDate}
          onChange={(e) => setCreationDate(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isSensitiveContent}
              onChange={(e) => {
                setIsSensitiveContent(e.target.checked);
              }}
              size={"small"}
              name="sensitiveContent"
            />
          }
          label="Sensitive Content"
        />
        <MDInput
          label="Full Article"
          multiline
          rows={5}
          size="small"
          value={fullArticle}
          onChange={(e) => setFullArticle(e.target.value)}
        />
        <MDButton color="warning" onClick={() => postShort()}>
          {" "}
          Create{" "}
        </MDButton>
      </div>
      <Grid item xs={12} sm={6} lg={3}>
        {renderError}
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        {renderSuccess}
      </Grid>
    </div>
  );
};

export default CreateShort;
