import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { particlesConfig } from "../../../utils/configParticles";
import Image from "../../elements/Image";
import orbit from "../../../assets/images/circle-orbit.svg";

const NavigationHeader = ({ location, title, description, edit, logo }) => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
  }, []);

  return (
    <div className={"container header-holder"}>
      <div className="details-holder">
        <div className="path-holder">
          {location.map((item, index) => {
            return (
              <div className="location" key={index}>
                <p
                  className={
                    index === location.length - 1
                      ? "path-name"
                      : "path-name bold"
                  }
                >
                  {item}
                </p>
                {index !== location.length - 1 && <div className="split" />}
              </div>
            );
          })}

          {/* <div className="header-circle"></div>
          <div className={"header-circle-orbit"}>
            <Image src={orbit} width={600} height={600} />
          </div> */}
        </div>
        {edit ? (
          edit
        ) : title ? (
          <p className="path-title">{title}</p>
        ) : (
          <img className={"header-logo"} src={logo} alt="logo" />
        )}
        <br />
        <p className="path-description typography-heading-large">
          {description}
        </p>
        <br />
        {/* <div className="divider" /> */}
        <br />
      </div>
    </div>
  );
};

export default NavigationHeader;
