import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Image from "../components/elements/Image";
import NavigationHeader from "../components/layout/partials/NavigationHeader";
import ScrollToTop from "../utils/ScrollToTop";

import heartEyes from "../assets/emoji/heart-eyes.svg";
import cute from "../assets/emoji/cute.svg";
import eyeglasses from "../assets/emoji/eyeglasses.svg";
import globe from "../assets/emoji/globe.svg";
import heart from "../assets/emoji/heart.svg";
import notification from "../assets/emoji/notification.svg";
import pen from "../assets/emoji/pen.svg";
import robot from "../assets/emoji/robot.svg";
import save from "../assets/emoji/save.svg";
import { Link } from "react-router-dom";
import google from "../assets/images/google.png";
import apple from "../assets/images/appstore.svg";
import Video from "../assets/videos/sample.mp4";
import VideoAZ from "../assets/videos/sample_az.mp4";
import VideoRU from "../assets/videos/sample_ru.mp4";
import { useSelector } from "react-redux";

const Features = () => {
  const { t } = useTranslation();
  let language = useSelector((state) => state.navigationReducer.language);

  const features = [
    {
      index: 1,
      name: "features_1",
      image: heartEyes,
    },
    {
      index: 2,
      name: "features_2",
      image: robot,
    },
    {
      index: 3,
      name: "features_3",
      image: pen,
    },
    {
      index: 4,
      name: "features_4",
      image: eyeglasses,
    },
    {
      index: 5,
      name: "features_5",
      image: heart,
    },
    {
      index: 6,
      name: "features_6",
      image: notification,
    },
    {
      index: 7,
      name: "features_7",
      image: save,
    },
    {
      index: 8,
      name: "features_8",
      image: globe,
    },
    {
      index: 9,
      name: "features_9",
      image: cute,
    },
  ];
  function renderVideo() {
    switch (language) {
      case "az":
        return VideoAZ;
      case "ru":
        return VideoRU;
      case "en":
        return Video;
    }
  }

  useEffect(() => {
    document.getElementById("mutiny-video").play();
  });

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("features")]}
              title={t("features")}
              description={t("features_paragraph_1_title")}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 100,
        }}
      >
        <div
          class="video-component video-component--mutiny"
          style={{
            backgroundColor: "transparent",
            boxShadow: "0px 0px 0px rgba(16, 30, 54, 0.09)",
          }}
        >
          <video
            className="video-holder"
            id="mutiny-video"
            muted
            width="800"
            height="802"
            autoplay
            src={renderVideo()}
            loop
            playsinline=""
          ></video>
        </div>
      </div>
      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-body">
            {t("features_paragraph_1_body")}
          </p>
        </div>
      </div>

      <div
        className={"container services-holder"}
        style={{ marginTop: "50px", zIndex: 10 }}
      >
        {features.map((element, index) => {
          return (
            <p className={"service-card"} key={index}>
              <div className="service-image-background">
                <Image
                  className={"service-image"}
                  src={element.image}
                  width={84}
                  height={84}
                />
              </div>
              <p className="service-title">{t(element.name)}</p>
              <p className="service-description">
                {t(element.name + "_description")}
              </p>
            </p>
          );
        })}
      </div>

      <div className={"store-buttons"}>
        <Link to="/play">
          <Image src={google} width={200} height={600} />
        </Link>
        <Link to="/store">
          <Image src={apple} width={165} height={600} />
        </Link>
      </div>
    </section>
  );
};

export default Features;
