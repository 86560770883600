import React from "react";
// import subscribeIcon from "../../../assets/images/map-image/subscribe-icon.svg";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
// import { addEmailToNewsletter } from "../../../actions";
import ActionModal from "./layout/partials/ActionModal";
import { Link } from "react-router-dom";

const Subscribe = () => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState({
    type: "block",
    title: t("message_sent_error"),
    body: t("message_sent_error_description"),
  });

  function openModal() {
    // addEmailToNewsletter(email)
    //   .then((res) => {
    //     setModalContent({
    //       type: "congrats",
    //       title: t("subscription_sent_successfully"),
    //       body: t("subscription_sent_successfully_description"),
    //     });
    //     setIsOpen(true);
    //   })
    //   .catch((e) => {
    //     setModalContent({
    //       type: "block",
    //       title: t("message_sent_error"),
    //       body: t("message_sent_error_description"),
    //     });
    //     setIsOpen(true);
    //   });
    // setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "40px",
    },
  };

  return (
    <div className={"container subscribe-background"}>
      <div className={"subscribe-body"}>
        <p className="subscribe-title" style={{ whiteSpace: "pre-line" }}>{`${t(
          "subscribe_body"
        )}`}</p>
        <div className="input-holder">
          <div className="hero-cta" style={{ marginTop: "0px" }}>
            <p className="button-white" href="/#" onClick={openModal}>
              {t("try_it_now")}
            </p>
            <Link to="/about" className="button-secondary">
              {t("learn_more")}
            </Link>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        // className="react-modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <ActionModal
          title={modalContent.title}
          body={modalContent.body}
          type={modalContent.type}
          onCancel={closeModal}
          onSuccess={closeModal}
          isCancellable={false}
        />
      </Modal>
    </div>
  );
};

export default Subscribe;
