import Rarible from "../images/social/rarible.svg";
import OpenSea from "../images/social/opensea.svg";
import Instagram from "../images/social/instagram-c.svg";
import TikTok from "../images/social/tiktok.svg";
import Twitter from "../images/social/twitter.svg";
import Facebook from "../images/social/facebook-c.svg";
import Youtube from "../images/social/youtube.svg";

export const projects = [
  {
    id: 1,
    title: "TikTok",
    description: "Project 3 description",
    link: "",
    image: <img src={TikTok} width={30} height={30} alt="" />,
  },
  {
    id: 2,
    title: "Instagram",
    link: "",
    description: "Project 1 description",
    image: <img src={Instagram} width={30} height={30} alt="" />,
  },

  {
    id: 5,
    title: "Twitter",
    link: "",
    description: "Project 5 description",
    image: <img src={Twitter} width={30} height={30} alt="" />,
  },
  {
    id: 6,
    title: "Facebook",
    link: "",
    description: "Project 5 description",
    image: <img src={Facebook} width={30} height={30} alt="" />,
  },
  {
    id: 7,
    title: "OpenSea",
    link: "",
    description: "Project 5 description",
    image: <img src={OpenSea} width={30} height={30} alt="" />,
  },
  {
    id: 8,
    title: "Rarible",
    link: "",
    description: "Project 5 description",
    image: <img src={Rarible} width={30} height={30} alt="" />,
  },
  {
    id: 9,
    title: "Youtube",
    link: "",
    description: "Project 5 description",
    image: <img src={Youtube} width={30} height={30} alt="" />,
  },
];
