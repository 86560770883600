import {
  MENU_CHANGE,
  LANGUAGE_CHANGE,
  SIGN_IN,
  ADMIN_MENU_CHANGE,
} from "../constants";
import adminRoutes from "../admin-routes";
const initialState = {
  language: "az",
  menu: 0,
  bearerToken: undefined,
  adminMenu: adminRoutes[0],
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_CHANGE:
      return {
        ...state,
        menu: action.payload,
      };
    case LANGUAGE_CHANGE:
      return {
        ...state,
        language: action.payload,
      };

    case SIGN_IN:
      return {
        ...state,
        bearerToken: action.payload,
      };

    case ADMIN_MENU_CHANGE:
      console.log("MENU CHANGE");
      return {
        ...state,
        adminMenu: action.payload,
      };
    default:
      return state;
  }
};
export default navigationReducer;
