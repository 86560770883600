import axios from "axios";
import { makeHeaders } from "utils";
import { HOST_URL, USER_HOST_URL } from "../constants";

export const signUp = (props) => {
  //Ready
  let {
    login,
    password,
    email,
    firstName,
    lastName,
    imageUrl,
    langKey,
    birthday,
  } = props;
  return axios.post(`${HOST_URL}/api/register`, props);
};

export const resetPasswordInit = (props, bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  let { email } = props;

  return axios.post(
    `${HOST_URL}/api/account/reset-password/init`,
    props,
    headers
  );
};

export const resetPasswordFinish = (props, bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  let { key, newPassword } = props;

  return axios.post(
    `${HOST_URL}/api/account/reset-password/finish`,
    props,
    headers
  );
};

export const signIn = (props, bearerToken) => {
  //Ready
  let { username, password, rememberMe } = props;
  return axios.post(`${HOST_URL}/api/authenticate`, props);
};

export const getUserSearchQueries = (bearerToken, page) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(
    `${HOST_URL}/api/user/search-history?page=${page}&size=20`,
    headers
  );
};

export const clearUserSearchQueries = (bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.delete(`${HOST_URL}/api/user/search-history`, headers);
};

export const selectDefaultLanguage = (language, bearerToken) => {
  //No endpoint
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${HOST_URL}/api/authenticate`, headers);
};

export const selectNotifications = (props, bearerToken) => {
  //Ready
  let {
    updatePushNotification,
    shortPushNotification,
    shortEmailNotification,
    updateEmailNotification,
  } = props;
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(
    `${HOST_URL}/api/user/notification-settings`,
    props,
    headers
  );
};

export const getNotifications = (bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(`${HOST_URL}/api/user/notification-settings`, headers);
};

export const getFAQ = (page) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(`${HOST_URL}/api/faq?page=${page}&size=20`, headers);
};

export const askQuestion = (question, bearerToken) => {
  //No endpoint
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${HOST_URL}/api/authenticate`, headers);
};

export const manageStoragePermissions = (storagePermissions, bearerToken) => {
  //No endpoint
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${HOST_URL}/api/authenticate`, headers);
};

export const changeUserData = (data, bearerToken) => {
  //No endpoint
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${HOST_URL}/api/authenticate`, headers);
};

export const changeProfilePicture = (props, bearerToken) => {
  //Ready
  let { headerImage } = props;
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${HOST_URL}/api/user/profile/image`, props, headers);
};

export const changeUserLocation = (props, bearerToken) => {
  //Ready
  let { lat, lng, country, city } = props;
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${HOST_URL}/api/user/location`, props, headers);
};

export const getUserLocation = (bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(`${HOST_URL}/api/user/location`, headers);
};

export const toggleShowTranslation = (toggle, bearerToken) => {
  //No endpoint
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.post(`${HOST_URL}/api/authenticate`, headers);
};

export const clearCurrentModelState = (bearerToken) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.delete(`${HOST_URL}/api/user/model`, headers);
};

export const getAppUsageStatistics = (bearerToken) => {
  //No endpoint
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(`${HOST_URL}/api/authenticate`, headers);
};

export const suspendAccount = (additionalInfo, bearerToken) => {
  //No endpoint
  return axios.delete(`${HOST_URL}/api/authenticate`, headers);
};

export const getUsersAll = (prompt, bearerToken, page, pageSize) => {
  //Ready
  let headers = { headers: makeHeaders(bearerToken) };
  return axios.get(
    `${USER_HOST_URL}/api/users?query=${prompt}&page=${page}&size=${pageSize}&sort=createdAt`,
    headers
  );
};
