/**
=========================================================
* The Short
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react


Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const buttonBase = {
  defaultProps: {
    disableRipple: false,
  },
};

export default buttonBase;
