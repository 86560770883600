/**
=========================================================
* The Short
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react


Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import AdminPanel from "./AdminPanel";

// The Short React Context Provider
import { MaterialUIControllerProvider } from "context";
import Dashboard from "./layouts/dashboard";
import { useSelector } from "react-redux";

const Admin = (props) => {
  let [layout, setLayout] = useState(<Dashboard />);
  let adminMenu = useSelector((state) => state.navigationReducer.adminMenu);

  console.log(props);
  return (
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <AdminPanel component={adminMenu.component} />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  );
};

export default Admin;
