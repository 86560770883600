import Contacts from "../../layouts/Contacts";
import Home from "../../layouts/Home";
import Features from "../../layouts/Features";
import Pricing from "../../layouts/Pricing";
import Community from "../../layouts/Community";

export const routes = [
  {
    title: "Home",
    index: 0,
    path: "/",
    name: "home",
    component: Home,
  },
  {
    title: "Features",
    index: 1,
    path: "/features",
    name: "features",
    component: Features,
  },
  {
    title: "Pricing",
    index: 2,
    path: "/pricing",
    name: "pricing",
    component: Pricing,
  },
  {
    title: "Tool",
    index: 3,
    path: "/tool",
    name: "tool",
    component: Community,
  },
  // {
  //   title: "Support",
  //   index: 4,
  //   path: "/support",
  //   name: "support",
  //   component: Support,
  // },
  {
    title: "About",
    index: 4,
    path: "/about",
    name: "about",
    component: Contacts,
  },
];
