export function nFormatter(num) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol
    : "0";
}

export function formatDateTime(dateTime) {
  const date = new Date(dateTime);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return `${month}/${day}/${year} ${hour}:${minute}`;
}

export function shortenAddress(address) {
  return (
    address.substring(0, 6) +
    "..." +
    address.substring(address.length - 4, address.length)
  );
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(password) {
  const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  return re.test(String(password));
}

export function validateWebsiteUrl(websiteUrl) {
  const re = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );

  if (websiteUrl === "") return true;

  return !!re.test(websiteUrl);
}

export function validateUsername(username) {
  const re = /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
  return re.test(String(username));
}

export function parseDate(date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function parseTime(time) {
  let hour = time.getHours();
  let minute = time.getMinutes();
  let sec = time.getSeconds();

  return `${hour}h : ${minute}m : ${sec}s`;
}

export function makeHeaders(bearerToken) {
  if (bearerToken === undefined || bearerToken === "") {
    return { "Content-Type": "application/json" };
  }
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + bearerToken,
  };
}

export function splitUserHeaders(product) {
  let userHeaders = product.headers;
  let owner = undefined;
  let creator = undefined;

  if (userHeaders !== null && userHeaders != undefined)
    for (let i = 0; i < userHeaders.length; i++) {
      let header = userHeaders[i];

      if (header.id === product.owner) owner = header;
      if (header.id === product.creator) creator = header;
    }
  else {
    return { owner: undefined, creator: undefined };
  }

  return { owner: owner, creator: creator };
}

export const rnd = (max = 256) => Math.random() * max;

export const generateColor = () => `rgb(${rnd()},${rnd()},${rnd()})`;

export const customSignInModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "5px",
    transform: "translate(-50%, -50%)",
    border: "transparent solid 0px",
    backgroundColor: "transparent",
    borderRadius: "0",
  },
};

export const signInModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "5px",
    backgroundColor: "transparent",
    transform: "translate(-50%, -50%)",

    border: "none",
    width: "100%",
  },
};
